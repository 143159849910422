@import "bootstrap";
@import "footer";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
//------------------------------------
//          Custom colors
//------------------------------------

$--ui-color-blue: #4482FF;
$--ui-color-blue-dark: #1849A9;
$--ui-color-blue-med: #92b4f6;
$--ui-color-blue-light: #EEF4FF;
$--ui-color-blue-bg: #e9f0fd;

$--ui-color-500: #172133;
$--ui-color-400: #57626D;
$--ui-color-300: #707A83;

$--ui-color-inversive-500: #ffffff;
$--ui-color-inversive-400: #f5f7fa;

$--ui-color-auth-background: #f8f8f8;

$--ui-color-black-light: #48556e;
$--ui-color-gray-dark: #8d95a5;
$--ui-color-gray: #d4d9e2;
$--ui-color-gray-light: #e6e9f0;
$--ui-color-grey-med: #8D95A5;


$--ui-color-green: #3AC267;
$--ui-color-green-dark: #268045;
$--ui-color-green-light: #93DDAC;
$--ui-color-green-bg: rgba(223, 250, 232, 0.3);


$--ui-color-red: #DF2059;

//------------------------------------
//             Shortcuts
//------------------------------------

%text-bold {
  font-style: normal;
  font-weight: 600;
}

%text-regular {
  font-style: normal;
  font-weight: 400;
}

//------------------------------------
//          Service Styles
//------------------------------------

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 18px;
}

p {
  padding: 0;
  margin: 0;
}

//-----------------------------------
//            Typography
//-----------------------------------

.spacer {
  display: flex;
  height: 1px;
  width: 100%;

  &-56 {
    display: flex;
    height: 56px;
    width: 100%;
  }

  &-50 {
    display: flex;
    height: 50px;
    width: 100%;
  }

  &-40 {
    display: flex;
    height: 40px;
    width: 100%;
  }

  &-32 {
    display: flex;
    height: 32px;
    width: 100%;
  }

  &-24 {
    display: flex;
    height: 24px;
    width: 100%;
  }

  &-16 {
    display: flex;
    height: 16px;
    width: 100%;
  }

  &-8 {
    display: flex;
    height: 8px;
    width: 100%;
  }

  &-10 {
    display: flex;
    height: 10px;
    width: 100%;
  }
}

.body {
  &-13 {
    @extend %text-regular;
    font-size: 13px;
    line-height: 20px;

    &-bold {
      @extend %text-bold;
      font-size: 13px;
      line-height: 20px;
    }
  }

  &-15 {
    @extend %text-regular;
    font-size: 15px;
    line-height: 24px;

    &-bold {
      @extend %text-bold;
      font-size: 15px;
      line-height: 24px;
    }
  }

  &-18 {
    @extend %text-regular;
    font-size: 18px;
    line-height: 28px;

    &-bold {
      @extend %text-bold;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.h1 {
  @extend %text-bold;
  font-size: 1.95em;
  line-height: 2.67em;
  margin: 0;
}

.h2 {
  @extend %text-bold;
  font-size: 1.67em;
  line-height: 133%;
  margin: 0;
}

.h3 {
  @extend %text-bold;
  font-size: 1.33em;
  line-height: 1.78em;
  margin: 0;
}

.h4 {
  @extend %text-bold;
  font-size: 1.11em;
  line-height: 1.55em;
  margin: 0;
}

.h5 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.33em;
  margin: 0;
}

//-----------------------------------
//              Colors
//-----------------------------------

.color {
  &-blue {
    color: $--ui-color-blue;
  }

  &-black {
    color: #222222;
  }

  &-text-primary {
    color: #2E2E3A;
  }

  &-300 {
    color: $--ui-color-300;
  }

  &-400 {
    color: $--ui-color-400;
  }

  &-500 {
    color: $--ui-color-500;
  }
  &-white {
    color: white;
  }

  &-inversive-500 {
    color: $--ui-color-inversive-500;
  }
}

// -----------------------------------
//              Helper
//-----------------------------------

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

$encoding-reference: (
                ('<', '%3C'),
                ('>', '%3E'),
                ('"', '\''),
                ('#', '%23'),
                ('&', '%26')
);

@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }
  @return 'data:image/svg+xml,' + $svg;
}



// -----------------------------------
//          Demo Page
//------------------------------------

body.adaptive {
  min-width: 320px !important;

  .container,
  .blur-portal {
    min-width: 320px !important;
  }

  .blur-modal__sms-confirm .input__wrapper {
    width: 100%;
  }

  .code-input {
    max-width: 100%;
    min-width: 100%;
  }
}

.shop {
  .logo-container {
    margin-top: 64px;

    .logo-wrapper {
      height: 40px;
      width: 52px;
      margin-right: 14px;
    }
  }
}
.shop-demo-ver1 {

  .object-fit-contain {
    object-fit: contain !important;
  }

  .object-fit-cover {
    object-fit: cover !important;
  }

  .shop-header {
    background-color: $--ui-color-inversive-400;
  }

  .shop-header .container {
    height: 80px;
    .logo-container.shop-demo {
      margin: auto 0;
      .logo-wrapper {
        width: 41px;
        height: 32px;
      }
    }
    .right-section-shop-header .vertical-divider {
      margin: auto 12px;
    }
  }

  .count-control {
    cursor: pointer;
    background-color: $--ui-color-blue-light;
    border-radius: 16px;
    padding: 8px;
    height: 44px;
    width: 120px;
    span {
      width: 40px;
      text-align: center;
    }
    input {
      background: none;
      border: none;
      width: 10px;
      padding: 0;
      &:focus-visible {
        outline: none;
      }
    }
  }

  .purchase-button {
    padding: 12px 24px;
    background: #48556E;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
  }
  .price {
    margin: auto 18px;
  }

  .checkout-btn, .checkout-usd-btn, .checkout-small-sum-btn {
    width: 320px;
    background-color: $--ui-color-blue;
    box-shadow: 0 5px 20px 0 #4482FF80;
    padding: 12px 0;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .button-logo-wrapper {
    margin-left: 12px;
    min-width: 26px;
    min-height: 20px;
    max-width: 26px;
    max-height: 20px;
    display: flex;
  }
  .image-section {
    padding-right: 40px;
    .main-image {
      border-radius: 16px;
      box-shadow: 0 4px 45px 0  #0000000F;
      img {
        width: 100%;
      }
    }
    .small-image {
      display: flex;
      flex: 1;
      border-radius: 16px;
      box-shadow: 0 4px 45px 0  #0000000F;
      margin: 24px 8px;
      img {
        width: 100%;
        object-fit: contain;
      }
      &:first-child,
      &:last-child {
        margin: 24px 0;
      }
    }
  }

  .product-card{
    width: 20rem;
    background-color: #fff;
    box-shadow: 0 15px 50px 0 rgba(80, 134, 224, .2);
    border-radius: 30px;
    word-wrap: break-word;

    .btn-holder {
      justify-content: flex-end;
      display: flex;
    }

    .card-text {
      min-height: 135px;
    }
  }

    .center-media {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 52%;
    }
  @media (max-width: 959px) {
    .image-section {
      padding: 0;
    }
    .checkout-btn {
      width: 100%;
    }
  }
  @media (max-width: 499px) {
    .h1 {
      font-size: 1.5em;
      line-height: 2.25em;
    }
    .price {
      margin: 0;
    }
  }
}
// -----------------------------------
//          breakpoint: XXL
//------------------------------------
//@include media-breakpoint-down(xxl) {
//  #multiwallet {
//    .description-section {
//      padding-left: 0px;
//    }
//  }
//}

// -----------------------------------
//          breakpoint: LG
//------------------------------------
@include media-breakpoint-down(lg) {
}

// -----------------------------------
//          breakpoint: MD
//------------------------------------
@include media-breakpoint-down(md) {
  // footer
  .footer-description {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    max-width: 210px;
  }

  .footer-actions-container {
    margin-bottom: 24px;
  }
}

// -----------------------------------
//          breakpoint: SM
//------------------------------------

@include media-breakpoint-down(sm) {

}